
 export const data = [
    {
        "date":"01 June '24",
        "title":"Anime Site with full backend API",
        "thumbnail":"animesite.png",
        "desc":"Fully pledged show streaming website.Has a login system and a media server",
        "techs":[
            "Javascript",
            "JSX",
            "TailwindCSS",
            "NodeJs",
            "Express",
            "MySQL"
        ]
    },
    {
        "date":"23 Jan '24",
        "title":"Wunderhome - Smart Home",
        "thumbnail":"animesite.png",
        "desc":"IoT project with android interface",
        "techs":[
            "Kotlin",
            "Jetpack Compose",
            "Micropython",
            "Django",
            "SqLite"
        ]
    }
]
export default data;