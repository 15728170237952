import React from "react";
import Techtile from "./Techtile";
import ".././App.css";

const ProjectTile = ({ data }) => {
  const t = data.techs;
  console.log(data);
  function show(d) {
    return <Techtile text={d} />;
  }
  return (
    <div className=" p-4 border border-slate-800 rounded-3xl mt-5">
      <p className="text-slate-500 font-thin text-[14px]">{data.date}</p>
      <a href={data.url}>
      <div className="flex">
      <p className="font-bold m-1">{data.title}</p>
        <svg
          className="w-[20px] h-[20px] text-gray-800 dark:text-white mt-1"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
          />
        </svg>
      </div>
      </a>
      <div className="cont w-full mt-3 flex">
        <img src={data.thumbnail} className="w-40 rounded-xl" />
        <p className="m-3 text-[14px]">{data.desc} </p>
      </div>
      <div className="TechStalk mt-1">{t.map((d) => show(d))}</div>
    </div>
  );
};

export default ProjectTile;
