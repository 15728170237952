import React from "react";
import Projects from "./Projects";

const Content = () => {
  return (
    <div className="font-sans lg:ml-[33.3333%] md:ml-[33.3333%] lg:w-2/3 md:w-2/3 sm:w-full lg:p-10 md:p-10 p-3 text-slate-300 leading-5 ">
        <p className='font-extrabold mt-5 mb-5 text-[22px]'>About</p>
      <p className="mb-2 p-2">
        My passion for software development stems from its blend of creativity,
        problem-solving, and potential for positive impact. It's more than just
        writing code; it's about crafting solutions that transcend boundaries
        and empower communities.
      </p>{" "}
      {/* <p className="mb-2">
        {" "}
        The dynamic nature of the field keeps me engaged, driving me to
        continuously learn and adapt to new technologies and challenges.
        Collaboration with diverse teams enriches my perspective and hones both
        technical and soft skills. I'm motivated by the opportunity to use
        technology as a force for good, addressing societal challenges and
        creating a more inclusive world.
      </p>{" "}
      <p>
        {" "}
        Looking ahead, I'm eager to embark on new challenges, whether founding
        startups, contributing to open-source projects, or pursuing research,
        driven by my lifelong calling to make a meaningful impact through
        software development.
      </p> */}
      <Projects/>
    </div>
  );
};

export default Content;
