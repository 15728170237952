
import './App.css';
import Content from './components/Content';
import Head from './components/Sidebar';

function App() {
  return (
    <div className='lg:flex md:flex'>
    <Head/>
    <Content/>
    
    
    </div>
  );
}

export default App;
