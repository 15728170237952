import React from 'react'
import data from '../data/data.mjs'
import ProjectTile from './ProjectTile'

const Projects = () => {
    
    function show(d){
        return(
            <ProjectTile data={d}/>
        )
    }

  return (
    <div className='mb-5'>
        <p className='font-extrabold mt-5 mb-5 text-[22px]'>Projects</p>
        {
            data.map(d => show(d))
        }
    </div>
  )
}

export default Projects