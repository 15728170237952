import React from "react";
import Socials from "./Socials";

const Head = () => {
  return (
    <div className="relative lg:fixed md:fixed lg:w-1/3 md:w-1/3 bg-[#041f1ef8] lg:h-[100vh] md:h-[100vh] sm:h-fit p-3 leading-8">
      <div className="mt-20 lg:mb-4 text-[32px] font-extrabold text-slate-300 sm:text-[32px] lg:text-[48px] md:text-[28px]">
        Fredrick Muyoma
      </div>
      <div className="lg:text-[22px] w-full justify-start text-slate-200">
        <p>Software Engineer</p>
      </div>
      <div className="lg:w-[220px] md:w-[243px] sm:w-[223px] mt-3 text-slate-500">
        I build scalable and industrial-level digital solutions.
      </div>
      <Socials/>
      <div className="lg:block md:block lg:fixed lg:bottom-2 md:fixed md:bottom-2 sm:hidden  text-slate-300 leading-5 font-thin text-[14px]">
        <p>phone: +254790780704</p>
        <p>E-mail: muyomafredrick@gmail.com</p>
      </div>
    </div>
  );
};

export default Head;
